<template>
  <div class="careers">
    <div class="banner">
      <img src="/assets/img/banner-5.jpg" alt="" class="banner">

      <div class="banner-text">
        <h2>{{ $t('navbar.careers') }}</h2>
      </div>
    </div>

    <div class="container is-max-widescreen my-6">

      <router-link to="/careers" class="is-flex is-align-items-center has-text-dark mb-4">
        <b-icon icon="arrow-left" class="mr-2" size="is-small"/>
        {{ $t('back') }}
      </router-link>
      
      <div class="careers-header">
        <h3>{{ $t('careers.title') }}</h3>
        <p v-html="$t('careers.desc')"></p>
      </div>

      <div class="pb-5">
        <template v-if="!isLoading">
          <div class="card card-career">
            <div class="card-content">
              <h4>{{ career.title }}</h4>
              <div class="mb-3">
                <p class="has-text-weight-bold">Job Desk</p>
                <div v-html="career.job_desk"></div>
              </div>
              <div>
                <p class="has-text-weight-bold">Requirements</p>
                <div v-html="career.requirements"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card card-career">
            <div class="card-content">
              <b-skeleton width="20%" class="mb-4" animated></b-skeleton>
              <b-skeleton width="40%" animated v-for="n in 5" :key="n"></b-skeleton>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.careers {
  .banner {
    position: relative;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-text {
      position: relative;
      z-index: 2;
      color: #FFFFFF;
      text-transform: capitalize;
    }

    h2 {
      font-size: 3rem;
      font-weight: bold;
    }

    img {
      position: absolute;
      vertical-align: middle;
      width: 100%;
      object-fit: cover;
      filter: brightness(.65);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .careers-header {
    color: #000000;

    h3 {
      font-size: 1.4rem;
      font-weight: 600;
    }

    margin-bottom: 1rem;
  }

  .card-career {
    h4 {
      font-size: 1.2rem;
      margin-bottom: .725rem;
      font-weight: bold;
    }

    ::v-deep {
      ul, ol {
        list-style-type: unset;
        padding-left: 1.25rem;
        li {
          padding-left: .5rem;
        }
      }
    }
  }
}
</style>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'CareersDetail',
  data: () => {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState([
      'career',
    ]),

    slug() {
      return this.$route.params.slug
    },
  },
  created() {
    this.isLoading = true
    this.getCareerBySlug(this.slug).finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    ...mapActions([
      'getCareerBySlug',
    ]),
  },
}
</script>
